import React from "react"
import SignIn from "../components/Account/SignIn"
import SEO from "../components/seo"
import Layout from "../components/Layout/layout"

const SignInPage = ({ data, location }) => {
  React.useEffect(() => {
    const siteHeader = document.querySelector(".site-header")
    siteHeader.style.background = "black"
  }, [])
  return (
    <Layout>
      <div className="page-container">
        <SEO title="Sign In" />
        <SignIn />
      </div>
    </Layout>
  )
}

export default SignInPage
